<template>
  <div>
    <a-row class="opt-steps">
      <a-col class="mb-20" :span="24">
        <a-row type="flex" justify="space-between">
          <a-col class="title">
            {{ tempOpportunity.title }}
            ({{ tempOpportunity.value | formatPricePtBr }})

            <a-tooltip v-if="tempOpportunity.origin_url">
              <template slot="title"> Acessar link de origem </template>
              <a-icon type="link" @click="onClickOpenOriginUrl" />
            </a-tooltip>
          </a-col>
          <a-col>
            <span class="extra"> </span>

            <a-button
              v-if="tempOpportunity.situation !== 'Congelado'"
              class="frozen-opt f12"
              size="small"
              type="primary"
              @click="openFreezeOptModal = true"
            >
              <img
                class="mr-5"
                src="@/assets/images/dashboard/opportunities/frozen.png"
                alt="frozen"
                width="14"
              />
              Congelar
            </a-button>

            <a-button
              v-if="tempOpportunity.situation === 'Congelado'"
              class="frozen-opt f12"
              size="small"
              type="primary"
            >
              <img
                class="mr-5"
                src="@/assets/images/dashboard/opportunities/frozen.png"
                alt="frozen"
                width="14"
              />
              Congelado
            </a-button>

            <a-button
              v-if="tempOpportunity.status !== 'Perdido'"
              class="lose-opt ml-10 f12"
              size="small"
              type="danger"
              @click="openLoseOptModal = true"
            >
              <a-icon type="close" /> Perdido
            </a-button>

            <a-popconfirm
              v-if="
                tempOpportunity.status !== 'Ganho' &&
                tempOpportunity.funnel_step.allow_contract == 1
              "
              title="Desejar marcar como GANHO?"
              ok-text="Sim"
              cancel-text="Não"
              placement="left"
              @confirm="submitWinOpt"
            >
              <a-button
                class="won-opt ml-10 f12"
                size="small"
                type="primary"
                :loading="winOpportunityLoading"
              >
                <a-icon type="check" /> Ganho
              </a-button>
            </a-popconfirm>

            <a-tooltip
              v-if="tempOpportunity.status === 'Perdido'"
              placement="bottomRight"
            >
              <template slot="title">
                <span>
                  <b>Motivo:</b>
                  {{ tempOpportunity.reason_to_lose_opportunity }} <br />
                  <b>Observação:</b>
                  {{
                    tempOpportunity.reason_observation_to_lose_opportunity
                  }}</span
                >
              </template>
              <a-button class="ml-10 f12" size="small" type="danger" ghost>
                <a-icon type="close" /> Perdido
              </a-button>
            </a-tooltip>
          </a-col>
        </a-row>

        <a-divider />
        <div class="step-name">
          Etapa atual:
          <b>{{
            opportunityStep.selected.name
              ? opportunityStep.selected.name
              : "Escolha uma etapa"
          }}</b>
        </div>

        <div class="steps">
          <div
            v-for="(item, index) in opportunitiesFunnelSteps.list"
            :key="index"
            class="step"
            :class="theStepClass(index)"
            @mouseover="onHoverStep(item, index)"
            @mouseleave="onMouseLeaveStep(item, index)"
            @click="onClickStep(item, index)"
          >
            {{ item.name }}
          </div>
        </div>
      </a-col>
    </a-row>

    <a-modal
      title="OPORTUNIDADE PERDIDA"
      :visible="openLoseOptModal"
      :footer="false"
      @cancel="openLoseOptModal = false"
    >
      <LoseOptModal
        v-if="openLoseOptModal"
        :tempOpportunity="tempOpportunity"
        @closeLoseOptModal="closeLoseOptModal"
      />
    </a-modal>

    <!-- <a-modal
      title="OPORTUNIDADE GANHA"
      :visible="openWinOptModal"
      :footer="false"
      @cancel="openWinOptModal = false"
      :width="300"
    >
      <WinOptModal
        v-if="openWinOptModal"
        :tempOpportunity="tempOpportunity"
        @closeWinOptModal="openWinOptModal = false"
      />
    </a-modal> -->

    <a-modal
      title="OPORTUNIDADE CONGELADA"
      :visible="openFreezeOptModal"
      :footer="false"
      @cancel="openFreezeOptModal = false"
    >
      <FreezeOptModal
        v-if="openFreezeOptModal"
        :tempOpportunity="tempOpportunity"
        @closeFreezeOptModal="closeFreezeOptModal"
      />
    </a-modal>
  </div>
</template>

<script>
import opportunitiesFunnelStepsMixins from "../mixins/opportunitiesFunnelStepsMixins";
import LoseOptModal from "../modals/LoseOptModal.vue";
//import WinOptModal from "../modals/WinOptModal.vue";
import formatThings from "@/mixins/general/formatThings";
import FreezeOptModal from "../modals/FreezeOptModal.vue";

export default {
  name: "OptFunnelSteps",
  props: {
    tempOpportunity: Object,
  },
  components: { LoseOptModal, FreezeOptModal },
  mixins: [opportunitiesFunnelStepsMixins, formatThings],
  data() {
    return {
      openLoseOptModal: false,
      openFreezeOptModal: false,
      winOpportunityLoading: false,
      opportunityStep: {
        hover: "",
        hoverIndex: -1,
        selected: {
          name: "",
        },
        selectedIndex: -1,
      },
    };
  },
  created() {
    this.$on("updateFunnelSteps2", this.updateFunnelSteps2);

    this.opportunitiesFunnelSteps.filters.funnelId =
      this.tempOpportunity.funnel_id;
    this.opportunitiesFunnelSteps.filters.status.selected = ["Ativado"];
    this.getOpportunitiesFunnelSteps();
  },
  beforeMount() {},
  mounted() {
    setTimeout(() => {
      this.opportunitiesFunnelSteps.list.forEach((item, index) => {
        if (item.name == this.tempOpportunity.step) {
          this.opportunityStep.selected.name = this.tempOpportunity.step;
          this.opportunityStep.selectedIndex = index;
        }
      });
    }, 600);
  },
  methods: {
    onClickOpenOriginUrl() {
      window.open(this.tempOpportunity.origin_url);
    },
    updateFunnelSteps2() {
      setTimeout(() => {
        this.opportunitiesFunnelSteps.filters.funnelId =
          this.tempOpportunity.funnel_id;
        this.opportunitiesFunnelSteps.filters.status.selected = ["Ativado"];
        this.getOpportunitiesFunnelSteps();

        this.opportunityStep = {
          hover: "",
          hoverIndex: -1,
          selected: {
            name: "",
          },
          selectedIndex: -1,
        };
      }, 100);
    },
    theStepClass(index) {
      let theClass = "";

      if (index <= this.opportunityStep.hoverIndex) theClass = "";
      if (index <= this.opportunityStep.selectedIndex) theClass += " active";

      return theClass;
    },
    closeLoseOptModal() {
      this.openLoseOptModal = false;
    },
    closeFreezeOptModal() {
      this.openFreezeOptModal = false;
    },
    duplicateOpt() {},
    onClickUnfreezeOpt() {
      this.$http
        .post("/opportunity/update-field", {
          id: this.tempOpportunity.id,
          field: "situation",
          value: "",
        })
        .then(() => {
          this.tempOpportunity.situation = "";
          this.$message.success("Opportunidade descongelada com sucesso!");
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempOpportunity.id,
            module: "opportunity",
            action: "unfreeze-opportunity",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} <b>descongelou</b> a  oportunidade ID ${this.tempOpportunity.id}.`,
          });
        });
    },
    updateContractOptId() {
      this.$http.post("/contract-v2/update-field", {
        id: this.tempOpportunity.contract_id,
        field: "opportunity_id",
        value: this.tempOpportunity.id,
      });
    },
    submitWinOpt() {
      this.winOpportunityLoading = true;

      this.$http
        .post("/opportunity/update-field", {
          id: this.tempOpportunity.id,
          field: "status",
          value: "Ganho",
        })
        .then(() => {
          this.tempOpportunity.status = "Ganho";

          // se existir contract id atualizar a opt id no contrato
          if (
            this.tempOpportunity.contract_id != 0 ||
            this.tempOpportunity.contract_id != null
          ) {
            this.$http.post("/opportunity/update-field", {
              id: this.tempOpportunity.id,
              field: "contract_id",
              value: this.tempOpportunity.contract_id,
            });
            this.updateContractOptId();
            this.getContractSales();
          }

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempOpportunity.id,
            module: "opportunity",
            action: "win-opportunity",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} marcou a oportunidade <b>ID ${this.tempOpportunity.id}</b> como <b>Ganho</b>.`,
          });

          this.$message.success("Oportunidade ganha!");
          this.winOpportunityLoading = false;
        })
        .catch(() => {
          this.winOpportunityLoading = false;
        });
    },
    onClickFreezeOpt() {
      this.$http
        .post("/opportunity/update-field", {
          id: this.tempOpportunity.id,
          field: "situation",
          value: "Congelado",
        })
        .then(() => {
          this.tempOpportunity.situation = "Congelado";
          this.$message.success("Opportunidade congelada com sucesso!");
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempOpportunity.id,
            module: "opportunity",
            action: "freeze-opportunity",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} <b>congelou</b> a  oportunidade ID ${this.tempOpportunity.id}.`,
          });
        });
    },
    sendOptToTrash() {
      this.$http
        .post("/opportunity/update-field", {
          id: this.tempOpportunity.id,
          field: "situation",
          value: "Lixeira",
        })
        .then(() => {
          this.tempOpportunity.situation = "Lixeira";
          this.$message.success(
            "Opportunidade enviada para lixeira com sucesso!"
          );
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempOpportunity.id,
            module: "opportunity",
            action: "trash-opportunity",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} enviou a  oportunidade ID ${this.tempOpportunity.id} para <b>Lixeira</b>.`,
          });
        });
    },
    onMouseLeaveStep() {
      this.opportunityStep.hoverIndex = -1;
      this.opportunityStep.hover = -1;
    },
    onHoverStep(item, index) {
      this.opportunityStep.hover = item;
      this.opportunityStep.hoverIndex = index;
    },
    onSelectFunnel(id) {
      this.opportunitiesFunnelSteps.filters.funnelId = id;
      this.getOpportunitiesFunnelSteps();
      this.opportunityStep = {
        hover: "",
        hoverIndex: -1,
        selected: "",
        selectedIndex: -1,
      };
    },
    onClickStep(item, index) {
      this.opportunityStep.selectedIndex = index;
      this.opportunityStep.selected = item;

      console.log(this.tempOpportunity.step, item.name, index);

      if (this.tempOpportunity.step != item.name) {
        this.$http.post("/opportunity/update-field", {
          id: this.tempOpportunity.id,
          field: "funnel_id",
          value: this.tempOpportunity.funnel_id,
        });

        this.$http
          .post("/opportunity/update-field", {
            id: this.tempOpportunity.id,
            field: "step",
            value: item.name,
          })
          .then(() => {
            this.$http
              .post("/log/create", {
                user_id: this.$store.state.userData.id,
                module_id: this.tempOpportunity.id,
                module: "opportunity",
                action: "change-opportunity-funnel-step",
                description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} passou a oportunidade da etapa <b>${this.tempOpportunity.step}</b> para a etapa <b>${item.name}</b>.`,
              })
              .then(() => {
                this.tempOpportunity.step = item.name;
                this.opportunityStep.selected.name = item.name;
                this.opportunityStep.selectedIndex = index;
              });

            this.tempOpportunity.funnel_step.allow_contract =
              item.allow_contract;
            this.$message.success("Etapa alterada com sucesso!");
            this.$emit("reloadHistory");
          });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.ant-divider
  margin: 10px 0

.opt-steps
  background: #fff
  padding: 15px 20px 0
  border-radius: 8px
  .extra
    color: #bbb
    .anticon
      transition: .3s
    .anticon-delete:hover
      color: red
    .anticon-copy:hover
      color: #3498DB
  .frozen-opt
    background: #2196f3
    border-color: #2196f3
  .won-opt
    background: #2ecc71
    border-color: #2ecc71
  .title
    color: #333
    font-weight: 600
    //font-size: 16px
.step-name
  margin-bottom: 5px
  font-size: 12px
  color: #333
.steps
  display: flex
  align-items: center
  justify-content: center
  align-content: center
  .step.active
    background: #be4178
  .step.active:before
    background: #be4178
  .step.hover
    background: #aaa
  .step.hover:before
    background: #aaa
  .step:before
    width: 43px
    height: 39px
    position: absolute
    right: -21px
    top: 4px
    border-top: 6px solid #FFF
    border-right: 6px solid #FFF
    -webkit-transform: scaleX(0.3) rotate(45deg)
    -moz-transform: scaleX(0.3) rotate(45deg)
    -ms-transform: scaleX(0.3) rotate(45deg)
    -o-transform: scaleX(0.3) rotate(45deg)
    transform: scaleX(0.3) rotate(45deg)
    content: " "
    background: #ccc
    cursor: pointer
    z-index: 1
  .step
    position: relative
    flex: 1
    height: 50px
    cursor: pointer
    text-align: center
    padding: 12px 10px
    border-radius: 4px
    font-size: 11px
    line-height: 1.2
    color: #FFF
    background: #ccc
    vertical-align: middle
</style>
