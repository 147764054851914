<template>
  <div>
    <a-row>
      <a-col :span="24">
        <div class="travel-input-outer mt-20 relative">
          <label class="filled"> Motivo do congelamento da oportunidade </label>
          <a-select
            class="travel-input"
            placeholder="Selecione"
            style="width: 100%"
            v-model="freezeOpportunity.reason"
            :disabled="tempOpportunity.situation == 'Congelado' ? true : false"
          >
            <a-select-option
              v-for="(reason, index) of freezingReasonsList"
              :key="index"
              :value="reason.name"
            >
              {{ reason.name }}
            </a-select-option>
          </a-select>
        </div>
      </a-col>

      <a-col class="mt-20" :span="13">
        <a-date-picker
          v-model="freezeOpportunity.date"
          placeholder="Data para retorno de contato"
          format="DD/MM/YYYY"
          valueFormat="YYYY-MM-DD"
        />
      </a-col>

      <a-col class="mt-20" :span="24">
        <a-textarea
          v-model="freezeOpportunity.obs"
          placeholder="Observações"
          :disabled="tempOpportunity.situation == 'Congelado' ? true : false"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-col>

      <a-col
        class="a-center mt-20"
        v-if="tempOpportunity.situation == 'Congelado' ? false : true"
        :span="24"
      >
        <a-button
          type="danger"
          :loading="freezeOpportunity.loading"
          @click="submitFreezeOpt()"
        >
          Confirmar
        </a-button>
      </a-col>
    </a-row>

    <!-- <a-popconfirm
    v-if="tempOpportunity.situation === 'Congelado'"
    title="Descongelar oportunidade?"
    ok-text="Sim"
    cancel-text="Não"
    @confirm="onClickUnfreezeOpt"
  >
    <a-button class="f12 frozen-opt" size="small" type="danger">
      <img
        class="mr-5"
        src="@/assets/images/dashboard/opportunities/frozen.png"
        alt="frozen"
        width="14"
      />
      Descongelar
    </a-button>
  </a-popconfirm> -->
  </div>
</template>

<script>
export default {
  name: "FreezeOptModal",
  props: {
    tempOpportunity: Object,
  },
  data() {
    return {
      freezeOpportunity: {
        loading: false,
        date: undefined,
        reason: undefined,
        obs: undefined,
      },
      freezingReasonsList: [],
    };
  },
  mounted() {
    this.$http
      .get(
        `/category-entry/list?page=1&per_page=100&category_id=28&status=Ativo&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.freezingReasonsList = data.data;
      });

    if (this.tempOpportunity.situation == "Perdido") {
      this.freezeOpportunity.reason =
        this.tempOpportunity.reason_to_lose_opportunity;
      this.freezeOpportunity.obs =
        this.tempOpportunity.reason_observation_to_lose_opportunity;
      //
    }
  },
  methods: {
    submitFreezeOpt() {
      if (
        this.freezeOpportunity.reason &&
        this.freezeOpportunity.obs &&
        this.freezeOpportunity.date
      ) {
        this.freezeOpportunity.loading = true;

        this.$http
          .post("/opportunity/update-field", {
            id: this.tempOpportunity.id,
            field: "situation",
            value: "Congelado",
          })
          .then(() => {
            let newEvent = {
              user_id: this.$store.state.userData.id,
              name: `Congelamento Oportunidade ID ${this.tempOpportunity.id}`,
              type: "Evento Pessoal",
              status: "Em aberto",
              date: this.freezeOpportunity.date,
              module: "opportunity",
              module_id: this.tempOpportunity.id,
              company_id: this.tempOpportunity.company_id,
              company_branch_id: this.tempOpportunity.company_branch_id,
              color: "#2196f3",
              description: `Motivo: ${this.freezeOpportunity.reason}\nObservação: ${this.freezeOpportunity.obs}`,
              modified_by: {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
              },
              attachment: "",
              extra_attachment: "",
              extra_attachment_2: "",
              other_attachments: "[]",
              value: 0,
            };

            this.$http.post("/event/create", newEvent).then(({ data }) => {
              this.$message.success(
                `Um evento ID ${data.id} foi criado no seu calendário para lembra-lo desta oportunidade.`, 4
              );
            });

            this.tempOpportunity.situation = "Congelado";
            this.tempOpportunity.reason_to_freeze_opportunity =
              this.freezeOpportunity.reason;
            this.tempOpportunity.reason_observation_to_freeze_opportunity =
              this.freezeOpportunity.obs;
            this.tempOpportunity.freeze_opportunity_until_date =
              this.freezeOpportunity.date;

            this.$http
              .post("/opportunity/update-multi-meta", {
                id: this.tempOpportunity.id,
                reason_to_freeze_opportunity: this.freezeOpportunity.reason,
                reason_observation_to_freeze_opportunity:
                  this.freezeOpportunity.obs,
                freeze_opportunity_until_date: this.freezeOpportunity.date,
              })
              .then(() => {
                this.$http.post("/log/create", {
                  user_id: this.$store.state.userData.id,
                  module_id: this.tempOpportunity.id,
                  module: "opportunity",
                  action: "freeze-opportunity",
                  description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} mudou o status da oportunidade ID ${this.tempOpportunity.id} para <b>${this.freezeOpportunity.reason}</b> pelo motivo: <b>${this.freezeOpportunity.obs}</b>.`,
                });
              });

            this.$emit("closeFreezeOptModal");
            this.freezeOpportunity.loading = false;
          })
          .catch(() => {
            this.freezeOpportunity.loading = false;
          });
      } else {
        this.$message.warning(
          "Selecione o motivo, data e uma observação sobre o congelamento."
        );
      }
    },
  },
};
</script>

<style lang="sass" scoped></style>
