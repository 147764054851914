export default {
  data() {
    return {
      opportunitiesFunnelSteps: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 100,
          total: 0,
          totalPages: 0,
        },
        details: {},
        stages: [],
        list: [],
        filters: {
          users: {
            list: [],
            selected: [],
          },
          funnelId: "",
          status: {
            list: [
              {
                label: "Ativado",
                value: "Ativado",
              },
              {
                label: "Desativado",
                value: "Desativado",
              },
            ],
            selected: [],
          },
          visibility: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },
          searchTerm: "",
          order: "ascend",
          orderBy: "step_order",
        },
      },
      funnelStepColor: undefined,
    };
  },
  methods: {
    changeOpportunitiesFunnelStepsPage(current) {
      this.opportunitiesFunnelSteps.pagination.page = current;
      this.getOpportunitiesFunnelSteps();
    },
    changeOpportunitiesFunnelStepsPageSize(current, pageSize) {
      this.opportunitiesFunnelSteps.pagination.page = current;
      this.opportunitiesFunnelSteps.pagination.perPage = pageSize;
      this.getOpportunitiesFunnelSteps();
    },
    opportunitiesFunnelStepsTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.opportunitiesFunnelSteps.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.opportunitiesFunnelSteps.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getOpportunitiesFunnelSteps();
    },
    mapOpportunitiesFunnelStepsList(arr) {
      return arr.map(({ name }) => {
        return {
          label: `${name}`,
          value: name,
        };
      });
    },
    getOpportunityFunnelStep(id) {
      // load
      this.$http
        .post(`/opportunities-funnel-steps/details?id=${id}`)
        .then(({ data }) => {
          this.opportunitiesFunnelSteps.details = data;
          this.form.setFieldsValue(data.raw);
          this.funnelStepColor = data.color;
        })
        .catch(({ response }) => {
          response;
          this.$message.error(response.data.message);
        });
    },
    getOpportunitiesFunnelSteps(samePage) {
      let filters = "";

      this.opportunitiesFunnelSteps.loading = true;

      if (
        this.opportunitiesFunnelSteps.filters.visibility.selected.length > 0
      ) {
        filters += `&visibility=${this.opportunitiesFunnelSteps.filters.visibility.selected}`;
      }

      if (this.opportunitiesFunnelSteps.filters.status.selected.length > 0) {
        filters += `&status=${this.opportunitiesFunnelSteps.filters.status.selected}`;
      }

      if (this.opportunitiesFunnelSteps.filters.funnelId) {
        filters += `&funnel_id=${this.opportunitiesFunnelSteps.filters.funnelId}`;
      }

      if (this.opportunitiesFunnelSteps.filters.users.selected.length > 0) {
        filters += `&user_id=${this.opportunitiesFunnelSteps.filters.users.selected}`;
      }

      filters += `&order=${this.opportunitiesFunnelSteps.filters.order}&order-by=${this.opportunitiesFunnelSteps.filters.orderBy}`;

      this.$http
        .get(
          `/opportunities-funnel-steps/list?page=${this.opportunitiesFunnelSteps.pagination.page}&per_page=${this.opportunitiesFunnelSteps.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.opportunitiesFunnelSteps.pagination.page = 1;
          }
          this.opportunitiesFunnelSteps.pagination.total = data.meta.total;
          this.opportunitiesFunnelSteps.pagination.totalPages =
            data.meta.total_pages;
          this.opportunitiesFunnelSteps.list = data.data;

          let Stages = [];
          data.data.forEach((step) => {
            //Stages.push({name: step.name, id: step.id});
            Stages.push(step.name);
          });

          this.opportunitiesFunnelSteps.stages = Stages;
          this.opportunitiesFunnelSteps.loading = false;
        })
        .catch(({ response }) => {
          this.opportunitiesFunnelSteps.loading = false;
          this.opportunitiesFunnelSteps.list = response.data.data;
          this.opportunitiesFunnelSteps.pagination.total =
            response.data.meta.total;
          this.opportunitiesFunnelSteps.pagination.totalPages =
            response.data.meta.total_pages;
        });
    },
  },
};
