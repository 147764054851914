<template>
  <div>
    <a-row>
      <a-col :span="24">
        <div class="travel-input-outer mt-20 relative">
          <label class="filled"> Motivo da perda da oportunidade </label>
          <a-select
            class="travel-input"
            placeholder="Selecione"
            style="width: 100%"
            v-model="loseOpportunity.reason"
            :disabled="tempOpportunity.status == 'Perdido' ? true : false"
          >
            <a-select-option
              v-for="(reason, index) of cancelationReasonsList"
              :key="index"
              :value="reason.name"
            >
              {{ reason.name }}
            </a-select-option>
          </a-select>
        </div>
      </a-col>

      <a-col class="mt-20" :span="24">
        <a-textarea
          v-model="loseOpportunity.obs"
          placeholder="Observações"
          :disabled="tempOpportunity.status == 'Perdido' ? true : false"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-col>

      <a-col
        class="a-center mt-20"
        v-if="tempOpportunity.status == 'Perdido' ? false : true"
        :span="24"
      >
        <a-button
          type="danger"
          :loading="loseOpportunity.loading"
          @click="submitLoseOpt()"
        >
          Confirmar
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "LoseOptModal",
  props: {
    tempOpportunity: Object,
  },
  data() {
    return {
      loseOpportunity: {
        loading: false,
        reason: undefined,
        obs: undefined,
      },
      cancelationReasonsList: [],
    };
  },
  mounted() {
    this.$http
      .get(
        `/category-entry/list?page=1&per_page=50&category_id=27&status=Ativo&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.cancelationReasonsList = data.data;
      });

    if (this.tempOpportunity.status == "Perdido") {
      this.loseOpportunity.reason =
        this.tempOpportunity.reason_to_lose_opportunity;
      this.loseOpportunity.obs =
        this.tempOpportunity.reason_observation_to_lose_opportunity;
      //
    }
  },
  methods: {
    submitLoseOpt() {
      if (this.loseOpportunity.reason && this.loseOpportunity.obs) {
        this.loseOpportunity.loading = true;

        this.$http
          .post("/opportunity/update-field", {
            id: this.tempOpportunity.id,
            field: "status",
            value: "Perdido",
          })
          .then(({ data }) => {
            data;
            this.tempOpportunity.status = "Perdido";
            this.tempOpportunity.reason_to_lose_opportunity =
              this.loseOpportunity.reason;
            this.tempOpportunity.reason_observation_to_lose_opportunity =
              this.loseOpportunity.obs;
            this.$http
              .post("/opportunity/update-multi-meta", {
                id: this.tempOpportunity.id,
                reason_to_lose_opportunity: this.loseOpportunity.reason,
                reason_observation_to_lose_opportunity:
                  this.loseOpportunity.obs,
              })
              .then(({ data }) => {
                data;
                this.$http
                  .post("/log/create", {
                    user_id: this.$store.state.userData.id,
                    module_id: this.tempOpportunity.id,
                    module: "opportunity",
                    action: "lose-opportunity",
                    description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} mudou o status da oportunidade ID ${this.tempOpportunity.id} para <b>${this.loseOpportunity.reason}</b> pelo motivo: <b>${this.loseOpportunity.obs}</b>.`,
                  })
                  .then(({ data }) => {
                    data;
                  });
              });

            this.$message.success("Oportunidade alterada com sucesso!");
            this.$emit("closeLoseOptModal");
            this.loseOpportunity.loading = false;
          })
          .catch(({ response }) => {
            response;
            this.loseOpportunity.loading = false;
          });
      } else {
        this.$message.warning(
          "Selecione o motivo da perda da oportunidade e escreva uma observação."
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
